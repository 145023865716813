export default {
  /**
   * Only load TMS when we're on the actual site, not when we're in the CMS.
   *
   * Detection needs to happen before DOMContentLoaded is fired. The snippet below
   * Simply checks for the existence of the XC edit stylesheet, since its href is
   * hardcoded. If anyone knows of a better way of doing this, please rip out and
   * replace this eyesore.
   *
   */
  get isEdit() {
    return !!document.querySelector('link[rel=stylesheet][href*=xcedit]');
  }
}