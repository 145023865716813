const readyState = {
  isDOMContentLoaded: document.readyState !== 'loading',
  isWindowLoaded: document.readyState === 'complete',
};

const handlers = {
  domContentLoaded: [],
  windowLoaded: [],
};

export function onDOMContentLoaded(cb) {
  if (readyState.isDOMContentLoaded) {
    return cb();
  }
  handlers.domContentLoaded.push(cb);
}

export function onWindowLoaded(cb) {
  if (readyState.isWindowLoaded) {
    return cb();
  }
  handlers.windowLoaded.push(cb);
}

function setDOMContentLoaded() {
  document.removeEventListener('DOMContentLoaded', setDOMContentLoaded);
  readyState.isDOMContentLoaded = true;
  handlers.domContentLoaded.forEach((handler) => handler());
  handlers.domContentLoaded = [];
}

function setWindowLoaded() {
  window.removeEventListener('load', setWindowLoaded);
  readyState.isWindowLoaded = true;
  handlers.windowLoaded.forEach((handler) => handler());
  handlers.windowLoaded = [];
}

document.addEventListener('DOMContentLoaded', setDOMContentLoaded);
window.addEventListener('load', setWindowLoaded);

export function isDOMContentLoaded() {
  return readyState.isDOMContentLoaded;
}

export function isWindowLoaded() {
  return readyState.isWindowLoaded;
}

export default {
  onWindowLoaded,
  onDOMContentLoaded,
  isDOMContentLoaded,
  isWindowLoaded,
};
