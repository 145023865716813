import radio from 'radio';
import cms from './cms';
import meta from './meta';
import chatbot from './chatbot';
// import glance from './glance';

export default function loadHeadScripts() {
  if (!cms.isEdit && !meta.PSDL_SKIP_HEADSCRIPTS) {
    // TODO: uncomment when Glance was implemented in FE apps
    // glance.load();
    const configs = {
      skipRelay42: !!meta.PSDL_SKIP_RELAY42,
      skipVirtualPageTracker: !!meta.PSDL_SKIP_VIRTUALPAGE_TRACKER,
    };
    // Loaded from s3 in packages\nn-psdl\build\gulp-tasks\package.js
    // Repository: https://dev.azure.com/nn-apps/Marketing%20Automation/_git/nn-analytics
    if (window.nnAnalytics) {
      window.nnAnalytics.initAnalytics(configs);
    }
    chatbot.load();
  }
}

window.addEventListener(
  'DOMContentLoaded',
  function () {
    loadHeadScripts();
  },
  false,
);

// expose radio as global to ensure there is only 1 radio instance loaded at all times
global.radio = radio;
