/**
 * this is used irrespective of loadScript utility as it is invoked in head script
 * which has to be lean . Compiles inside scope of Webpack and inside scope of Babel,
 *  but not inside scope of Polyfills.  Doesnt support promises.
 *
 * It uses concept of callback to return information that the script is loaded or failed
 */
import getNonce from '@nn/psdl-utility/src/nonce';

function scriptLoader(url, options, callbackSuccess, callbackErr) {
  callbackSuccess = callbackSuccess || function () {};
  callbackErr = callbackErr || function () {};

  let flag = false;
  let script = document.querySelector(`script[src="${url}"]`);
  if (!script) {
    script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.nonce = getNonce();

    if (typeof options === 'object' && options !== null) {
      const dataset = options.dataset || {};
      delete options.dataset;
      Object.assign(script, options || {});
      Object.assign(script.dataset, dataset || {});
    }

    script.onload = script.onreadystatechange = function () {
      if (!flag && (!this.readyState || this.readyState === 'complete')) {
        flag = true;
        callbackSuccess.apply();
      }
    };

    script.onerror = function () {
      callbackErr.apply('error in loading script');
    };
  }
  document.head.appendChild(script);
}

export default scriptLoader;
