import meta from "@nn/psdl-utility/src/meta.helper";

export default {
  get PSDL_SKIP_HEADSCRIPTS() {
    return meta.getMetaContentByName('PSDL_SKIP_HEADSCRIPTS') === 'true' ||
      meta.getMetaContentByName('psdl_skip_headscripts') === 'true'
  },
  get PSDL_SKIP_RELAY42() {
    return meta.getMetaContentByName('PSDL_SKIP_RELAY42') ||
      meta.getMetaContentByName('PSDL_SKIP_RELAY42')
  },
}