import scriptLoader from '../scriptLoader/scriptLoader';
import meta from '@nn/psdl-utility/src/meta.helper.js';
import documentState from '@nn/psdl-utility/src/documentState';

const ENV_DOMAINS = {
  default: '-tst',
  uat: '-acc',
  production: '',
  prod: '',
  prd: '',
};

const PORTALS = ['nn.nl', 'adviseur.nn.nl'];

const BLACKLISTEDPAGES = ['app-to-web', 'uitloggen'];

const CHATBOT_EVENT_FUNCTIONS = {
  'chatbot/CHAT_WINDOW_OPENED': () => {
    publishChatbotEvent('open');
  },
  'chatbot/CHAT_WINDOW_CLOSED': () => {
    publishChatbotEvent('close');
  },
  'chatbot/QUICK_REPLY_CLICKED': (payload) => {
    if (!payload) {
      return publishError('Missing payload in QUICK_REPLY_CLICKED event');
    }
    publishChatbotEvent('send', payload.value);
  },
  'chatbot/MESSAGE': (payload) => {
    if (!payload) {
      return publishError('Missing payload in MESSAGE event');
    }
    if (!payload.history && payload.sender && payload.sender.type === 'Bot') {
      if (!payload.text || !payload.text.value) {
        return publishError(
          'Incorrect payload.text structure in MESSAGE event [payload.text = ' +
            JSON.stringify(payload.text) +
            ']',
        );
      }
      publishChatbotEvent('botMessage', payload.text.value);
    }
  },
};

const isNotCorrectPortal = () => {
  const portal = meta.getMetaContentByName('portal');
  return PORTALS.indexOf(portal) === -1;
};

const isBlackListed = () => {
  const targetGroup = meta.getMetaContentByName('doelgroep');
  return (
    targetGroup && BLACKLISTEDPAGES.indexOf(targetGroup.toLowerCase()) !== -1
  );
};

const determineScriptToLoad = () => {
  const env = (meta.getMetaContentByName('env') || '').toLowerCase();
  const cbDomain = ENV_DOMAINS.hasOwnProperty(env)
    ? ENV_DOMAINS[env]
    : ENV_DOMAINS.default;
  return `https://chatbot.api${cbDomain}.nn-group.com/bot.js`;
};

const publishError = (e) => {
  window.app.analytics.publish('error-tracking', 'chatbot error', {
    event_action: e,
    nonInteraction: 1,
  });
};

const publishChatbotEvent = (action, label) => {
  window.app.analytics.publish('form-tracking', 'chatbot', {
    event_action: action,
    event_label: label,
  });
};

const load = () => {
  if (window.chatbot_loaded || isNotCorrectPortal() || isBlackListed()) {
    return;
  }
  const scriptToLoad = determineScriptToLoad();
  documentState.onDOMContentLoaded(() => {
    scriptLoader(
      scriptToLoad,
      { async: true },
      scriptLoadSuccess,
      scriptLoadError,
    );
  });
};

const scriptLoadError = () => {
  publishError('error in loading bot script');
};

const scriptLoadSuccess = () => {
  window.chatbot_loaded = true;
  const userId =
    window.app && window.app.analytics
      ? window.app.analytics.getData().user_id_sap
      : null;
  const chatPreference = meta.getMetaContentByName('chat_preference');
  if (!window.nnchatbot) {
    publishError('error in bootstrapping bot script');
  } else {
    window.nnchatbot.bootstrap({
      accountHash: userId,
      preference: chatPreference,
    });
    window.nnchatbot.subscribe((event) => {
      if (CHATBOT_EVENT_FUNCTIONS[event.type]) {
        CHATBOT_EVENT_FUNCTIONS[event.type](event.payload);
      }
    });
  }
};

const chatbot = {
  load,
};

export default chatbot;
